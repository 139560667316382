import { render, staticRenderFns } from "./yibinPlum.vue?vue&type=template&id=345bd7ce&scoped=true&"
import script from "./yibinPlum.vue?vue&type=script&lang=js&"
export * from "./yibinPlum.vue?vue&type=script&lang=js&"
import style0 from "./yibinPlum.vue?vue&type=style&index=0&id=345bd7ce&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "345bd7ce",
  null
  
)

export default component.exports