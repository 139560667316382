<template>
  <div class="border" style="height: 90%;">
    <div class="chart-curve" ref="curve"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      myChart: null,
      TemDatas: [], 
      TemTimes: [],
    };
  },
  props: {
    TemData: {
      type: Array,
      default: null,
    },
    TemTime: {
      type: Array,
      default: null,
    },
  },
  created() {
    this.initTem();
  },
  mounted() {
    this.showChartData();
    this.startInterval();
  },
  methods: {
    startInterval: function () {
      if (this.timeId) {
        clearInterval(this.timeId);
      }
      this.timeId = setInterval(() => {
        this.initTem();
        this.showChartData();
      }, 1000);
    },
    initChart() {
      if (this.myChart === null) {
        this.myChart = this.$echarts.init(this.$refs.curve, "chalk");
      }
      return this.myChart;
    },
    initTem() {
      this.TemDatas = this.TemData;
      this.TemTimes = this.TemTime;
    },
    //显示图表数据
    showChartData() {
      //this.chartInstance=this.$echarts.init(this.$refs.curve,'chalk');
      let result = this.initChart();
      window.addEventListener("resize", function () {
        result.resize();
      });
      let initOption = {
        color: ["#d9efff", "#0575ff"],
        tooltip: {
          trigger: "axis",
        },
        label: {
          show: false,
          position: "top",
          color: "#fff",
        },
        legend: {
          right: 10,
          top: 0,
          itemWidth: 15,
          itemHeight: 6,
          itemGap: 10,
          data: ["相对湿度"],
        },
        grid: {
          top: "30%",
          left: "4%",
          right: "10%",
          bottom: ".1%",
          containLabel: true,
        },
        animation: true,
        animationDuration: function (idx) {
          // 越往后的数据延迟越大
          return idx * 300;
        },
        animationEasing: "cubicInOut",
        xAxis: {
          type: "category",
          name: "时刻",
          data: this.TemTimes,
          axisLabel: {
            formatter: '{value} 分'
          },
        },
        yAxis: {
          type: "value",
          name: "单位:ppm",
          splitLine: {
            show: false,
          },
          axisLine: {
            show: true,
          },
        },
        series: [
          {
            name: "相对湿度",
            type: "bar",
            barWidth: "32%",
            itemStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(
                  0,
                  1,
                  0,
                  0,
                  [
                    {
                      offset: 0,
                      color: "#d9efff",
                    },
                    {
                      offset: 0.8,
                      color: "#0575ff",
                    },
                  ],
                  false
                ),
                shadowColor: "rgba(0, 0, 0, 0.1)",
              },
            },
            xAxisIndex: 0,
            yAxisIndex: 0,
            data: this.TemDatas,
          },
        ],
      };
      result.setOption(initOption);
    },
  },
};
</script>
<style>
.chart-curve {
  width: 100%;
  height: 100%;
}
</style>
