import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/error",
  },
  {
    path: "/error",
    component: () => import("../views/Error.vue"),
  },
  {
    path: "/navigation",
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/login",
    component: () => import("../views/Login/Login.vue"),
  },
  {
    path: "/export",
    component: () => import("../views/ExportPage/ExportPage.vue"),
  },
  {
    path: "/jinggang",
    component: () => import("../views/JgMountain/ScreenPage.vue"),
  },
  {
    path: "/xinjianExp",
    component: () => import("../views/XinjianExp/ScreenPage.vue"),
  },
  {
    path: "/xinjianEco",
    component: () => import("../views/XinjianEco/ScreenPage.vue"),
  },
  {
    path: "/pingshan",
    component: () => import("../views/Pingshan/ScreenPage.vue"),
  },
  {
    path: "/yongxin",
    component: () => import("../views/Yongxin/ScreenPage.vue"),
  },
  {
    path: "/ganzhou",
    component: () => import("../views/Ganzhou/ScreenPage.vue"),
  },
  {
    path: "/shanggao",
    component: () => import("../views/Shanggao/ScreenPage.vue"),
  },
  {
    path: "/fengxin",
    component: () => import("../views/Fengxin/ScreenPage.vue"),
  },
  {
    path: "/fish",
    component: () => import("../views/fish/ScreenPage.vue"),
  },
  {
    path: "/yuganFish",
    component: () => import("../views/YuganFish/ScreenPage.vue"),
  },
  {
    path: "/yuganAqua",
    component: () => import("../views/YuganAqua/ScreenPage.vue"),
  },
  {
    path: "/xiaofuchaye",
    component: () => import("../views/Xiaofuchaye/ScreenPage.vue"),
  },
  {
    path: "/fishIndex",
    component: () => import("../views/FinshIndex/ScreenPage.vue"),
  },
  {
    path: "/mushRoom",
    component: () => import("../views/Mushroom/ScreenPage.vue"),
  },
  {
    path: "/monitor",
    component: () => import("../views/Fengxin/cpn/Monitor.vue"),
  },
  {
    path: "/fengNav",
    component: () => import("../views/Fengxin/nav/navView.vue"),
  },
  {
    path: "/clodStorage",

    component: () => import("../views/Fengxin/coldStorage/ScreenPage.vue"),
  },
  {
    path: "/mjy",

    component: () => import("../views/Mjy/ScreenPage.vue"),
  },
];

const router = new VueRouter({
  mode: "hash",
  routes,
});
router.beforeEach((to, from, next) => {
  if (to.matched.length === 0) {
    // 如果路由未找到，则跳转到错误页面
    next("/error");
  } else {
    // 路由存在，继续导航
    next();
  }
});

export default router;
